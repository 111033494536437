@font-face {
  font-family: "Neris Semibold";
  src: url("../fonts/Neris-SemiBold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neris Light";
  src: url("../fonts/Neris-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Neris Thin";
  src: url("../fonts/Neris-Thin.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  width: 100%;
  height: 100%;
  font-family: "Neris Light" !important;
  overflow-x: hidden;
  background-color: rgb(12, 27, 43) !important;
  font-size: 16px;
  font-weight: 400;
}

a {
  text-decoration: none !important;
  display: inline-block;
  transition: ease-in-out 0.3s;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

img {
  max-width: 100%;
}

button {
  outline: none !important;
}

::placeholder {
  color: #8f8f8f !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #24356c;
}

::-webkit-scrollbar-thumb:hover {
  background: #24356c;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 100px;
  z-index: 999;
  background-color: white;
}

.mt100 {
  margin-top: 100px;
}

header .logo,
footer .logo {
 padding: 5px;
}

.themeBtnRed,
.themeBtnWhite:hover,
.themeBtnRed:active,
.themeBtnRed:hover {
  background: #ff5757 !important;
  color: white !important;
}

.themeBtnWhite {
  background: white;
  color: #172254;
}

/* .h_Shadow {
  box-shadow: 0 1px 100px 0 rgb(150 150 223 / 0.14);
} */

.m-50 {
  margin-top: 100px !important;
}

.btn-signup{
  appearance: none;
  display: inline-flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  width: auto;
  border: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  font-weight: 600;
  padding: 0px 12px;
  text-decoration: none;
  user-select: none;
  transition: all 300ms ease-in-out 0s;
  border-radius: 100rem;
  font-size: 1rem;
  line-height: 1.33;
  height: 36px;
  min-width: 36px;
  color: rgb(20, 39, 57);
  background-color: rgb(50, 203, 203);
}

.btn-login{
  appearance: none;
  display: inline-flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  width: auto;
  border: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  font-weight: 600;
  padding: 0px 12px;
  text-decoration: none;
  user-select: none;
  transition: all 300ms ease-in-out 0s;
  border-radius: 100rem;
  font-size: 1rem;
  line-height: 1.33;
  height: 36px;
  min-width: 36px;
  color: rgb(249, 250, 251);
  background-color: rgb(44, 60, 77);
}

.nav-link{
  color:rgb(173, 183, 191) !important;
}

.row .nav navbar-toggler{
  margin-top: 400px !important;
}

.navbar-toggler {

  background-color: white !important;

}

.themeRedColor {
  color: #ff5757;
}

.lightGreyBg1 {
  background: #f7f8fd;
}

.lightGreyBg2 {
  background: #fcfcfc;
}

.dialogBG {
  background: #e9ecf7;
}

.themeBlueColor,
.nav-link:hover,
.nav-link:visited {
  color: #172254 !important;
}

.footerBG {
  background: rgb(12, 27, 43) !important;
  color: white !important;
}

.f_shadow {
  box-shadow: 0px 0px 2px 2px #ff5757;
}

@media (max-width: 450px) {
  .g-recaptcha {
    scale: 0.7;
    margin-left: -37px;
  }
}

/* 404 Page  */
.Page404content {
  height: 85vh;
  position: relative;
  z-index: 1;
  background-color: #d2e1ec;
  background-image: linear-gradient(to bottom, #bbcfe1 0%, #e8f2f6 80%);
  overflow: hidden;
}

.snow {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 20;
}

.main-text {
  padding: 10vh 20px 0 20px;
  text-align: center;
  line-height: 2em;
  font-size: 5vh;
}

.home-link {
  font-size: 0.6em;
  font-weight: 400;
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
  border-bottom: 1px dashed rgba(93, 115, 153, 0.5);
}

.home-link:hover {
  opacity: 1;
}

.ground {
  height: 200px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #f6f9fa;
  box-shadow: 0 0 10px 10px #f6f9fa;
}

.ground:before,
.ground:after {
  content: "";
  display: block;
  width: 250px;
  height: 250px;
  position: absolute;
  top: -62.5px;
  z-index: -1;
  background: transparent;
  transform: scaleX(0.2) rotate(45deg);
}

.ground:after {
  left: 50%;
  margin-left: -166.6666666667px;
  box-shadow: -345px 255px 15px #b4bed1, -575px 625px 15px #97a6c0,
    -945px 855px 15px #91a1bc, -1190px 1210px 15px #b7c1d3,
    -1510px 1490px 15px #8e9eba, -1790px 1810px 15px #a7b4c9,
    -2080px 2120px 15px #94a3be, -2425px 2375px 15px #7e90b0,
    -2650px 2750px 15px #b0bccf, -2955px 3045px 15px #aab6cb,
    -3315px 3285px 15px #b4bed1, -3560px 3640px 15px #94a3be,
    -3880px 3920px 15px #a1aec6, -4175px 4225px 15px #a1aec6,
    -4525px 4475px 15px #b7c1d3, -4750px 4850px 15px #8798b6;
}

.ground:before {
  right: 50%;
  margin-right: -166.6666666667px;
  box-shadow: 350px -250px 15px #a4b1c8, 555px -645px 15px #7e90b0,
    885px -915px 15px #b0bccf, 1235px -1165px 15px #b7c1d3,
    1545px -1455px 15px #a4b1c8, 1830px -1770px 15px #8193b2,
    2085px -2115px 15px #adb9cd, 2420px -2380px 15px #97a6c0,
    2730px -2670px 15px #adb9cd, 3025px -2975px 15px #a7b4c9,
    3305px -3295px 15px #8193b2, 3565px -3635px 15px #8e9eba,
    3875px -3925px 15px #b7c1d3, 4190px -4210px 15px #adb9cd,
    4520px -4480px 15px #8a9bb8, 4800px -4800px 15px #bac4d5;
}

.mound {
  margin-top: -150px;
  font-weight: 900;
  font-size: 180px;
  text-align: center;
  color: #dd4040;
  pointer-events: none;
  font-family: "Dosis", sans-serif;
}

.mound:before {
  content: "";
  display: block;
  width: 600px;
  height: 200px;
  position: absolute;
  left: 50%;
  margin-left: -300px;
  top: 50px;
  z-index: 1;
  border-radius: 100%;
  background-color: #e8f2f6;
  background-image: linear-gradient(to bottom, #dee8f1, #f6f9fa 60px);
}

.mound:after {
  content: "";
  display: block;
  width: 28px;
  height: 6px;
  position: absolute;
  left: 50%;
  margin-left: -150px;
  top: 68px;
  z-index: 2;
  background: #dd4040;
  border-radius: 100%;
  transform: rotate(-15deg);
  box-shadow: -56px 12px 0 1px #dd4040, -126px 6px 0 2px #dd4040,
    -196px 24px 0 3px #dd4040;
}

.mound_text {
  transform: rotate(6deg);
}

.mound_spade {
  display: block;
  width: 35px;
  height: 30px;
  position: absolute;
  right: 50%;
  top: 42%;
  margin-right: -250px;
  z-index: 0;
  transform: rotate(35deg);
  background: #dd4040;
}

.mound_spade:before,
.mound_spade:after {
  content: "";
  display: block;
  position: absolute;
}

.mound_spade:before {
  width: 40%;
  height: 30px;
  bottom: 98%;
  left: 50%;
  margin-left: -20%;
  background: #dd4040;
}

.mound_spade:after {
  width: 100%;
  height: 30px;
  top: -55px;
  left: 0%;
  box-sizing: border-box;
  border: 10px solid #dd4040;
  border-radius: 4px 4px 20px 20px;
}

@media (min-width: 992px) {
  .ant-select-dropdown {
    width: 600px !important;
  }
}

@media (max-width: 500px) {
  .main-text {
    padding: 15vh 20px 0 20px;
  }

  .mound {
    margin-top: -100px;
    font-size: 130px;
  }
}
/* 404 Page End */

.h-100vh {
  height: 100vh;
}