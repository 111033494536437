/* @import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");

#Home .cardBox {
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
  aspect-ratio: 1 / 1;
}

#Home .cardBox::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 150%;
  background: -webkit-linear-gradient(to right, #ff0080, #ff8c00, #40e0d0);
  background: linear-gradient(to right, #ff0080, #ff8c00, #40e0d0);
  transform-origin: center;
  animation: glowing 7s linear infinite;
}

@keyframes glowing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

#Home .cardBox .card {
  width: 95%;
  height: 95%;
  overflow:hidden;
}

#Home .cardBox .card .card-title {
  font-weight: 900;
  font-size: 3rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

#Home .cardBox .card p {
  color: white;
  font-weight: 900;
}
#Home .cardBox .card .card-img {
  object-fit: cover;
  animation: zoom-in-zoom-out 5s ease infinite;
  position: relative;
  width: 100%;
  height: 100%;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.4, 1.4);
  }
  100% {
    transform: scale(1, 1);
  }
}




.home {
  height: 90vh;
  position: relative;
}
video {
  opacity: 0.3;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-content {
  position: relative;
  padding-top: 80px;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.home p {
  font-size: clamp(25px, 4vw, 40px);
  margin-top: 40px;
}

.home-content button {
  display: block;
  font-size: clamp(14px, 1.5vw, 18px);
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  background: transparent;
  color: #fff;
  margin: 50px auto 0;
  padding: 12px 20px;
  cursor: pointer;
}


.Heading--mg {
  font-size: 4.5rem !important;
  line-height: 5.5rem;
}
.Heading {
  line-height: 80px !important;
  font-size: 4.5rem;
  margin: 0 0 1rem;
  font-weight: 600;
  font-family: "Audiowide";

}


/* 16 */
/* .btn-16 {
  border: none;
  color: #000;
}
.btn-16:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  box-shadow:
   -7px -7px 20px 0px #fff9,
   -4px -4px 5px 0px #fff9,
   7px 7px 20px 0px #0002,
   4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
}
.btn-16:hover {
  color: #000;
}
.btn-16:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}
.btn-16:active {
  top: 2px;
}
 */
