#Tours.container {
    max-width: 1440px;
  }
  
  #Tours .card:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  }
  #Tours .card {
    transition: 250ms box-shadow;
  }
  
  #Tours .card .user-image {
    opacity: 0;
    transition: 250ms opacity;
  }
  
  #Tours .card:hover .user-image {
    opacity: 1;
    transition: 250ms opacity;
  }
  
  #Tours .above-stretched-link {
    position: relative;
    z-index: 2;
  }
  
  #Tours .card-profile-img {
    max-width: 50px;
    max-height: 50px;
    margin-top: -60px;
    margin-bottom: 5px;
    border: 3px solid #fff;
    border-radius: 100%;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    outline: 1px rgba(0, 0, 0, 0.25) solid;
    text-align: center;
  }
  
  #Tours .pagination a.page-link {
    color: #3097d1;
    border: 1px solid #9da9b0;
  }
  #Tours .pagination .active a.page-link {
    color: white;
    background-color: #3097d1;
  }
  