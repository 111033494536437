#MusicStudio {
  background: #0c1b2b;
  color: white;
}

#MusicStudio .Tags {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
}

#MusicStudio .Tags::-webkit-scrollbar {
  display: none;
}

#MusicStudio .Tags span {
  display: inline-block;
  background-color: rgb(44, 60, 77);
  font-weight: 700;
  border-radius: 100rem;
  cursor: pointer;
  font-size: 1rem;
  line-height: 24px;
  padding: 8px 12px;
  margin: 5px;
  white-space: nowrap;
}

#MusicStudio .Tags span:hover {
  color: rgb(249, 250, 251);
  background-color: rgb(67, 82, 97);
}
#MusicStudio .Tags span.active {
  color: rgb(20, 39, 57);
  background-color: rgb(223, 227, 233);
}

#MusicStudio .musicCarousel .card {
  border: none;
  background-color: transparent;
  color: white;
  margin: 20% 0%;
  padding: 5%;
}

#MusicStudio .musicCarousel .slick-prev,
#MusicStudio .musicCarousel .slick-next {
  top: 40%;
  background-color: white;
  color: black;
  opacity: 1;
  padding: 20px;
  border-radius: 100%;
}
#MusicStudio .musicCarousel .slick-prev::after,
#MusicStudio .musicCarousel .slick-next::after {
  top: 33%;
  left: 30%;
}

#MusicStudio #chromatic {
  background: radial-gradient(
    100% 50% at 100% 0.1%,
    rgb(44, 60, 77) 0%,
    rgb(20, 39, 57) 100%
  );
}

#MusicStudio #searchByCats .cat {
  padding: 30px;
  border: 1px solid rgb(20, 39, 57);
}

#MusicStudio #searchByCats .cat:hover {
  background: radial-gradient(
    100% 50% at 100% 0.1%,
    rgb(44, 60, 77) 0%,
    rgb(20, 39, 57) 100%
  );
}

.music-studio {
  height: 60vh;
  position: relative;
}
video {
  opacity: 0.3;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.music-studio-content {
  position: relative;
  padding-top: 50px;
  color: #fff;
  text-align: center;
}
h1 {
  font-family: Playfair Display, serif;
  font-size: clamp(45px, 7vw, 130px);
  line-height: 1.1;
}

.music-studio p {
  font-size: clamp(25px, 4vw, 40px);
  margin-top: 10px;
}

.home-content button {
  display: block;
  font-size: clamp(14px, 1.5vw, 18px);
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  background: transparent;
  color: #fff;
  margin: 50px auto 0;
  padding: 12px 20px;
  cursor: pointer;
}

.input-grey-rounded{
font-size: 16px;
line-height: 1.5;
background: rgb(44, 60, 77);
border: 1px solid rgb(44, 60, 77);
background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='%238C92A0' d='M11.44 10.73l4.41 4.42a.5.5 0 1 1-.7.7l-4.42-4.41a6.5 6.5 0 1 1 .7-.7v-.01zM6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'></path></svg>");
background-repeat: no-repeat;
background-position: 15px 10px;
background-size: 20px 20px;
border-radius: 100px;
width: 500px;
padding: .5em 1em .5em 2.5em;
} 

.Heading-Music--mg {
  font-size: 3.5rem !important;
  line-height: 5.5rem;
}
.Heading-Music {
  line-height: 80px !important;
  margin: 0 0 1rem;
  font-weight: 600;
  font-family: "Audiowide";

}

.player {
  position: relative;
  width: 230px;
  background: #f1f3f4;
  box-shadow: 0 50px 80px rgba(0, 0, 0, 0.25);
}
.player .imgbx {
  position: relative;
  width: 100%;
  height: 100px;
}
.player .imgbx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.player audio {
  width: 100%;
  outline: none;
}
.whatsapp-btn{
  background-color: transparent;
  padding: 10px;
  width: 20rem;
  background-color: #25d366;
  color: white;
  border: none;
  border-radius: 20px;
}