#VirtualTour .card,
#VirtualTour .card .card-img {
  border: none;
  border-radius: 0px;
}

#VirtualTour .GVTLogo {
  max-width: 20%;
  margin-left: 10%;
  margin-top: 3%;
  filter: drop-shadow(2px 4px 3px #00000073);
}

#VirtualTour .GSVTrustedLong img {
  margin: 5%;
  width: 95%;
}

#VirtualTour .mainText {
  margin: 0% 0% 5% 5%;
  width: 95%;
  font-size: 50px;
  font-weight: 900;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 20px;
}

#VirtualTour .darkText {
  color: #125ca3;
  border-radius: 20px;
  background-color: white;
  padding: 15px 15px 15px 60px;
}

#VirtualTour .lightText {
  color: white;
  padding: 15px 15px 15px 60px;
}

#VirtualTour .GSVTrusted img {
  max-width: 20%;
  margin-left: 20%;
}

#VirtualTour .DColor {
  color: #125ca3;
}
#VirtualTour .LColor {
  color: white;
}

#VirtualTour ul.samples li {
  display: inline;
  margin-right: 40px;
  font-size: 20px;
  text-decoration: underline;
}

#VirtualTour ul.whyGVT li {
  font-size: 30px;
  margin: 10px 0px;
  list-style: none;
}

#VirtualTour .card h2 {
  font-size: 60px;
  margin: 50px 0px;
  text-align: justify;
}

#VirtualTour .card h5 {
  font-size: 35px;
  margin: 50px 0px;
  text-align: justify;
}

#VirtualTour h5 b.LText {
  font-weight: 900;
  font-size: 40px;
}

#VirtualTour h2 .thick-hr {
  border: none;
  height: 2px;
  opacity: 1;
}
#VirtualTour h6 {
  font-size: 30px;
  margin: 50px 0px;
  text-align: justify;
}
/* ############ Resposive ############ */

@media (min-width: 1200px) and (max-width: 1500px) {
  #VirtualTour .mainText {
    font-size: 40px;
  }
  #VirtualTour .card h2 {
    font-size: 55px;
    margin: 15px 0px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  #VirtualTour .mainText {
    font-size: 30px;
  }
  #VirtualTour .card h2 {
    font-size: 45px;
    margin: 15px 0px;
  }
  #VirtualTour ul.whyGVT li {
    font-size: 25px;
  }
  #VirtualTour h6 {
    margin: 50px 150px 50px 0px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  #VirtualTour .mainText {
    font-size: 20px;
  }
  #VirtualTour .darkText,
  #VirtualTour .lightText {
    padding: 5px 5px 5px 20px;
    margin: 0px;
    border-radius: 15px;
  }

  #VirtualTour .card h2 {
    font-size: 35px;
    margin: 15px 0px;
  }
  #VirtualTour .card h5 {
    font-size: 20px;
    margin: 15px 0px;
  }

  #VirtualTour h5 b.LText {
    font-size: 22px;
  }

  #VirtualTour ul.whyGVT li {
    font-size: 20px;
  }

  #VirtualTour h6 {
    font-size: 20px;
    margin: 15px 0px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  #VirtualTour .mainText {
    font-size: 15px;
  }

  #VirtualTour .darkText,
  #VirtualTour .lightText {
    padding: 5px 5px 5px 20px;
    margin: 0px;
    border-radius: 10px;
  }

  #VirtualTour .card h2 {
    font-size: 25px;
    margin: 10px 0px;
  }

  #VirtualTour .card h5 {
    font-size: 17px;
    margin: 10px 0px;
  }

  #VirtualTour h5 b.LText {
    font-size: 18px;
  }

  #VirtualTour ul.samples li {
    font-size: 15px;
  }
  #VirtualTour ul.whyGVT li {
    font-size: 15px;
  }

  #VirtualTour h6 {
    font-size: 15px;
    margin: 10px 0px;
  }
}

@media (max-width: 576px) {

  #VirtualTour .mainText {
    font-size: 10px;
    border-radius: 5px;
  }

  #VirtualTour .darkText,
  #VirtualTour .lightText {
    padding: 5px 5px 5px 20px;
    margin: 0px;
    border-radius: 5px;
  }

  #VirtualTour .card h2 {
    font-size: 15px;
    margin: 5px 0px;
  }

  #VirtualTour .card h5 {
    font-size: 12px;
    margin: 5px 0px;
  }

  #VirtualTour h5 b.LText {
    font-weight: 900;
    font-size: 13px;
  }
  #VirtualTour ul.samples li {
    font-size: 10px;
  }
  #VirtualTour ul.whyGVT li {
    font-size: 10px;
    margin: 0px;
  }
  #VirtualTour h6 {
    font-size: 10px;
    margin: 5px 40px 5px 0px;
  }
}


.virtual-studio {
  height: 60vh;
  position: relative;
}
.Heading-Virtual--mg {
  font-size: 3.5rem !important;
}
.Heading-Virtual {
  margin-top: 50px;
  line-height: 80px !important;
  font-weight: 600;
  font-family: "Audiowide";

}
.Sub-Heading-Virtual--mg {
  font-size: 1rem !important;
}
.Sub-Heading-Virtual {
  line-height: 80px !important;
  font-weight: 600;
  font-family: "Audiowide";

}

.dot {
  height: 5px;
  width: 5px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
}


.snip *,
.snip *:before,
.snip *:after {
  box-sizing: border-box;
  transition: all 0.45s ease;
}

.snip {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 315px;
  min-width: 230px;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  font-size: 16px;
  margin: 10px;
  text-align: left;
  transform: translateZ(0);
}

.snip:hover .snip__title,
.snip:hover .snip__text {
  transform: translateY(0);
  opacity: 0.9;
  transition-delay: 0.2s;
}

.snip::before,
.snip::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #b81212;
  opacity: 0.5;
  transition: all 0.45s ease;
}

.snip::before {
  transform: skew(30deg) translateX(-80%);
}

.snip::after {
  transform: skew(-30deg) translateX(-70%);
}

.snip:hover::before {
  transform: skew(30deg) translateX(-20%);
  transition-delay: 0.05s;
}

.snip:hover::after {
  transform: skew(-30deg) translateX(-10%);
}

.snip:hover .snip__figcaption::before {
  transform: skew(30deg) translateX(-40%);
  transition-delay: 0.15s;
}

.snip:hover .snip__figcaption::after {
  transform: skew(-30deg) translateX(-30%);
  transition-delay: 0.1s;
}

.snip__image {
  backface-visibility: hidden;
  max-width: 100%;
  vertical-align: top;
}

.snip__figcaption {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  bottom: 0;
  padding: 25px 40% 25px 20px;
}

.snip__figcaption::before,
.snip__figcaption::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #b81212;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  content: "";
  opacity: 0.5;
  z-index: -1;
}

.snip__figcaption::before {
  transform: skew(30deg) translateX(-100%);
}

.snip__figcaption::after {
  transform: skew(-30deg) translateX(-90%);
}

.snip__title,
.snip__text {
  margin: 0;
  opacity: 0;
  letter-spacing: 1px;
}

.snip__title {
  font-family: "Teko", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
}

.snip__text {
  font-size: 0.9em;
}

.snip__link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.Heading-Virtual-View-Heading{
  font-size: 6rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Audiowide";
}

.browse-project-btn{
  padding: 15px 35px 15px 35px;
  border-radius: 30px;
  font-weight: 600;
  border: none;
}

.browse-project-btn:hover{
  background-color: transparent;
  border: 2px solid white;
  color: white;
  
}