 /* Media Query for Mobile Devices */
 @media (max-width: 480px) {
   .Heading{
    font-size: 40px !important;
    line-height: 3rem !important;
    padding: 20px;
   }
   .input-grey-rounded{
    width: 300px;
    } 

    .Heading-Music--mg {
        font-size: 1.5rem !important;
      }
      .Heading-Music {
        margin-top: 15px !important;
        line-height: 40px !important;
        margin: 0 0 1rem;
        font-weight: 600;
        font-family: "Audiowide";
      
      }

      .Heading-Virtual--mg {
        font-size: 1.5rem !important;
      }


      .Heading-Virtual {
        margin-top: 70px !important;
        line-height: 20px !important;
        font-size: 20px;
        font-weight: 600;
        font-family: "Audiowide";
      
      }
      .side-padding{
        padding: 0px 10px 0px 20px;
      }
    .Heading-Virtual-View-Heading{
        font-size:30px;
        padding: 4px;
      }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {

}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {

}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {

}

/* Media Query for Large screens */
@media (min-width: 1281px) {
 
}